import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { H3, H4, Heading } from '../../components';

import css from './SectionCategoriesMaybe.module.css';
import { HOSPITAL_DOCTOR } from '../../util/types';
import { mapFieldsOptionsToLabel } from '../../util/data';

const SectionCategoriesMaybe = props => {
  const { publicData, heading, listingConfig } = props;
  const { category, sub_category = [], speciality = [], sub_speciality = [] } = publicData;
  if (!category) return null;

  const subCategoryEnumOptions = listingConfig?.listingFields?.find(lc => lc.key === 'sub_category')
    ?.enumOptions;

  const specialityEnumOptions = listingConfig?.listingFields?.find(lc => lc.key === 'speciality')
    ?.enumOptions;
    
  const subSpecialityEnumOptions = listingConfig?.listingFields?.find(
    lc => lc.key === 'sub_speciality'
  )?.enumOptions;

  const specialityCategory = mapFieldsOptionsToLabel(specialityEnumOptions, speciality);

  const subCategoryMaybe = !!sub_category ? (
    <div className={css.subCategoryText}>
      <H4 className={css.subTitle}>
        <FormattedMessage id="ListingPage.subCategoryTitle" />
      </H4>
      <span className={css.subListText}>
        <ul className={css.subListTextList}>
          {/* {subCategory} */}
          {sub_category.map(s => (
            <li key={s}>{mapFieldsOptionsToLabel(subCategoryEnumOptions, s)}</li>
          ))}
        </ul>
      </span>
    </div>
  ) : null;
  const specialityMaybe =
    !!speciality && speciality.length ? (
      <div className={css.subCategoryText}>
        <H4 className={css.subTitle}>
          <FormattedMessage id="ListingPage.specialityTitle" />
        </H4>
        <ul className={css.subListTextList}>
          <li>{specialityCategory}</li>
        </ul>
      </div>
    ) : null;
  const subSpecialityMaybe =
    !!sub_speciality && sub_speciality.length ? (
      <div className={css.subCategoryText}>
        <H4 className={css.subTitle}>
          <FormattedMessage id="ListingPage.subSpecialityTitle" />
        </H4>
        <ul className={css.subListTextList}>
          {sub_speciality.map(s => (
            <li key={s}>{mapFieldsOptionsToLabel(subSpecialityEnumOptions, s)}</li>
          ))}
        </ul>
      </div>
    ) : null;

  const doctorDataMaybe =
    category === HOSPITAL_DOCTOR ? (
      <div className={css.categoryWrapper}>
        {subCategoryMaybe}
        {specialityMaybe}
        {subSpecialityMaybe}
      </div>
    ) : null;

  return (
    <div>
      <H3 className={css.titleTextBorder}>{heading}</H3>
      <div className={css.categoryWrapper}>
        <span className={css.categoryText}>{category}</span>
      </div>

      {doctorDataMaybe}
    </div>
  );
};

export default SectionCategoriesMaybe;
